import React from 'react'
import { useTranslation } from 'react-i18next'

const Explorer = ({ url }) => {
  const { i18n } = useTranslation()
  const t = i18n.getResourceBundle(i18n.language)

  return (
    <div className="Explorer">
      <iframe className="frame" src={url} allowFullScreen />
      <div className="footer">
        <a href={t.explorer.privacy_url}>{t.explorer.privacy}</a>
        <span> &nbsp; | &nbsp; </span>
        <span>Google Data Studio</span>
      </div>
    </div>
  )
}

export default Explorer
