import { USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE, USER_SET, USER_LOGOUT } from 'store/actions/user'
import { isAuthenticated, isLibraryMode } from 'utils/backend'

export const RoleEnum = Object.freeze({
  LICENSE: 'License',
  ESSENTIAL: 'Essential',
  PREMIUM: 'Premium'
})

const initialState = {
  isAuthenticated: isAuthenticated(),
  isLibraryMode: isLibraryMode(),
  loginType: null,
  role: RoleEnum.ESSENTIAL,
  error: null
}

const userReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case USER_LOGIN_SUCCESS:
      return { ...state, isAuthenticated: true, isLibraryMode: isLibraryMode(), loginType: payload.loginType }
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLibraryMode: false,
        loginType: payload.loginType,
        error: payload.error
      }
    case USER_SET:
      return { ...state, ...payload.currentUser }
    case USER_LOGOUT:
      return { ...initialState, isAuthenticated: false, isLibraryMode: false, }
    default:
      return state
  }
}

export default userReducer
